import React, {useState} from "react"

function Header() {

    const [mobileOpen, setMobileOpen] = useState(false);

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6" style={{zIndex: "20"}}>
            <div
                className="flex justify-between items-center border-b-2 border-gray-30 py-6 md:justify-start md:space-x-10" style={{zIndex: "20"}}>
                <div className="lg:w-0 lg:flex-1" style={{zIndex: "20"}}>
                    <a href="/" className="font-black no-underline text-main-700 tracking-wide flex items-center">
                            <span className='mr-1 '>
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.2682 2.70091C21.1672 4.43108 22.2784 6.93491 22.7567 9.55127C23.2349 12.1676 23.0521 14.9106 21.8142 17.0205C20.5764 19.1305 18.2695 20.6075 15.7516 21.5359C13.2337 22.4643 10.5048 22.8441 8.08537 22.0985C5.66594 21.3389 3.5419 19.454 2.03679 17.1049C0.545751 14.7418 -0.340434 11.9144 0.123758 9.3262C0.587951 6.73798 2.40252 4.38888 4.61095 2.70091C6.83345 1.027 9.44981 0.0142172 12.0943 0.000150789C14.7388 -0.0139157 17.3833 0.956669 19.2682 2.70091Z" fill="#A0C2E4"/>
                                <path d="M16.053 7.40917C17.0437 8.31187 17.6235 9.61821 17.873 10.9833C18.1226 12.3483 18.0272 13.7794 17.3813 14.8803C16.7355 15.9811 15.5319 16.7517 14.2182 17.2361C12.9045 17.7205 11.4808 17.9186 10.2185 17.5297C8.95614 17.1334 7.84795 16.1499 7.06268 14.9243C6.28474 13.6914 5.82238 12.2162 6.06457 10.8658C6.30676 9.51547 7.25349 8.28985 8.40572 7.40917C9.56528 6.53583 10.9303 6.00742 12.3101 6.00008C13.6898 5.99274 15.0695 6.49913 16.053 7.40917Z" fill="#5D99D5"/>
                            </svg>

                            </span>

                        <span className='text-base inline-block tracking-wider  '>
                            TEAMWIDE
                        </span>
                    </a>
                </div>
                <div className="-mr-2 -my-2 md:hidden" style={{zIndex: "20"}}>
                    <button onClick={() => setMobileOpen(true)}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M4 6h16M4 12h16M4 18h16"/>
                        </svg>
                    </button>
                </div>
                <nav className="hidden md:flex space-x-10" style={{zIndex: "20"}}>
                    <a href="/writing"
                       className="text-base leading-6 font-medium text-charcoal-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                        Writing
                    </a>
                    <a href="/tools"
                       className="text-base leading-6 font-medium text-charcoal-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                        Tools
                    </a>
                    <a href="https://www.getrevue.co/profile/team-talk"
                       className="text-base leading-6 font-medium text-charcoal-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                        Team Talk
                    </a>
                    <a href="/about"
                       className="text-base leading-6 font-medium text-charcoal-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                        About
                    </a>
                </nav>

                <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0" style={{zIndex: "20"}}>

                </div>
            </div>

            { mobileOpen && <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" style={{zIndex: "30"}}>
                <div className="rounded-lg shadow-md">
                    <div className="rounded-lg bg-white shadow-xs overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                            <div>
                                <a href="/" className="font-black no-underline text-main-700 tracking-wide flex items-center">
                                    <span className='mr-1'>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.2682 2.70091C21.1672 4.43108 22.2784 6.93491 22.7567 9.55127C23.2349 12.1676 23.0521 14.9106 21.8142 17.0205C20.5764 19.1305 18.2695 20.6075 15.7516 21.5359C13.2337 22.4643 10.5048 22.8441 8.08537 22.0985C5.66594 21.3389 3.5419 19.454 2.03679 17.1049C0.545751 14.7418 -0.340434 11.9144 0.123758 9.3262C0.587951 6.73798 2.40252 4.38888 4.61095 2.70091C6.83345 1.027 9.44981 0.0142172 12.0943 0.000150789C14.7388 -0.0139157 17.3833 0.956669 19.2682 2.70091Z" fill="#A0C2E4"/>
                                            <path d="M16.053 7.40917C17.0437 8.31187 17.6235 9.61821 17.873 10.9833C18.1226 12.3483 18.0272 13.7794 17.3813 14.8803C16.7355 15.9811 15.5319 16.7517 14.2182 17.2361C12.9045 17.7205 11.4808 17.9186 10.2185 17.5297C8.95614 17.1334 7.84795 16.1499 7.06268 14.9243C6.28474 13.6914 5.82238 12.2162 6.06457 10.8658C6.30676 9.51547 7.25349 8.28985 8.40572 7.40917C9.56528 6.53583 10.9303 6.00742 12.3101 6.00008C13.6898 5.99274 15.0695 6.49913 16.053 7.40917Z" fill="#5D99D5"/>
                                        </svg>
                                    </span>

                                    <span className='text-base inline-block tracking-wider  '>
                                TEAMWIDE
                            </span>
                                </a>
                            </div>
                            <div className="-mr-2">
                                <button
                                    onClick={() => setMobileOpen(false)}
                                    type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                                    <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="px-2 pt-2 pb-3">
                            <a href="/writing"
                           className="block px-3 py-2 rounded-md text-base font-medium text-charcoal-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
                                Writing
                            </a>
                            <a href="/tools"
                               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-charcoal-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
                                Tools
                            </a>
                            <a href="http://talk.teamwide.com/"
                               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-charcoal-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
                                Team Talk
                            </a>
                            <a href="/about"
                               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-charcoal-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out">
                                About
                            </a>
                        </div>
                    </div>
                </div>
            </div> }

        </div>
    )
}

export default Header
